import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-3x);

  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;

  > div {
    &[type='square'] {
      height: 91px !important;
      width: 100% !important;
    }
  }
`;
