import { FsRadioButton, ListSelectType } from '@printi/ds-offset-react-core';
import { ChangeEventHandler } from 'react';
import * as S from './TextSelect.styles';

interface TextSelectProps {
  optionName: string;
  value: string;
  selected?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: () => void;
}

export const TextSelect = ({
  optionName,
  value,
  selected = false,
  onChange,
  onClick,
}: TextSelectProps) => {
  return (
    <S.ListSelect
      type={ListSelectType.Text}
      heading={optionName}
      selected={selected}
      onClick={onClick}
    >
      <FsRadioButton
        value={value}
        selected={selected}
        inputProps={{
          onChange: onChange,
        }}
      />
    </S.ListSelect>
  );
};
