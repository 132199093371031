import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { TCustomPage } from '@/services/product/types';

interface UseModalCustomPageHooksProps {
  customPage?: TCustomPage;
}

export const useModalCustomPageHooks = ({
  customPage,
}: UseModalCustomPageHooksProps) => {
  const { setCustomPageOption, setCustomPage } = useProductConfigurationStore();

  const supportText = () => {
    return `min: ${customPage?.min} , max: ${customPage?.max}`;
  };

  const isMultipleOf = (num: number, multipleOf: number) => {
    if (multipleOf === 0) {
      return false;
    }
    return num % multipleOf === 0;
  };

  const validPages = (pages: number) => {
    if (!customPage?.min || !customPage.max) {
      return '';
    }

    const hasMultipleProperty = customPage?.allow;
    const multiple = customPage?.multiple || 0;

    if (pages < customPage.min || pages > customPage.max) {
      return 'Insira uma quantidade válida';
    } else if (hasMultipleProperty && !isMultipleOf(pages, multiple)) {
      return `A quantidade deve ser um número múltiplo de ${multiple}`;
    }

    return '';
  };

  const handleConfirm = (pages: number) => {
    const option = {
      id: `custom=${pages}`,
      name: `Personalizado ${pages} páginas`,
      image: '',
    };
    const customPage = {
      isUsed: true,
      pages,
    };
    setCustomPageOption(option);
    setCustomPage(customPage);
  };

  return {
    validPages,
    supportText,
    handleConfirm,
  };
};
