import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5x);
  align-self: stretch;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-half);
  align-self: stretch;
`;

export const TitleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);
`;
