'use client';

/* eslint-disable import/order */
import {
  FsTab,
  FsTabItem,
  FsTopSection,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { forwardRef, useState } from 'react';
import { BlocksContent } from '@strapi/blocks-react-renderer';
import { Faq } from '@mf/common/repositories/bff/product/index';
import * as S from './Specifications.styles';
import { ContentRenderer } from '@/components/main/ContentRenderer';
import { FaqItems } from '@/components/main/FaqItems';

interface SpecificationsProps {
  content: BlocksContent[];
  faq?: Faq[];
}

// eslint-disable-next-line
export const Specifications = forwardRef<HTMLDivElement, SpecificationsProps>(
  ({ content, faq }, ref) => {
    const [tabIndex, setTabIndex] = useState<number>(0);
    const hasFaq = !!faq?.length;

    return (
      <S.Container ref={ref}>
        <FsTopSection
          heading="Especificações do produto"
          showDescription={false}
          headingProps={{
            tag: HeadingTag.H2,
          }}
        />
        <S.Content>
          {hasFaq ? (
            <FsTab>
              <FsTabItem
                label="Descrição completa"
                index={0}
                onClick={() => setTabIndex(0)}
              />
              <FsTabItem label="FAQ" index={1} onClick={() => setTabIndex(1)} />
            </FsTab>
          ) : (
            <></>
          )}

          <S.ActiveView $active={tabIndex === 0}>
            <ContentRenderer content={content} />
          </S.ActiveView>

          <S.ActiveView $active={tabIndex === 1}>
            <FsTopSection
              heading="Dúvidas frequentes sobre o produto"
              showDescription={false}
              headingProps={{
                tag: HeadingTag.H2,
              }}
            />
            <FaqItems items={faq} />
          </S.ActiveView>
        </S.Content>
      </S.Container>
    );
  },
);
