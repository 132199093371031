import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  FlagType,
  FsDescription,
  FsFlag,
  FsHeading,
  FsInputText,
  FsListContent,
  FsSkeleton,
  HeadingSize,
  HeadingTag,
  ListContentType,
} from '@printi/ds-offset-react-core';
import {
  FormEvent,
  KeyboardEvent,
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useLayout } from '@mf/common/components/Media/Layout';
import { AxiosResponse } from 'axios';
import * as S from './SimulateShipping.styles';
import { ShippingList } from './ShippingList';
import { useSimulateShippingHooks } from './SimulateShipping.hooks';

interface SimulateShippingProps {
  token: string;
}

// eslint-disable-next-line
export const SimulateShipping = forwardRef<
  HTMLDivElement,
  SimulateShippingProps
>(({ token }, ref) => {
  const [zipCode, setZipCode] = useState<string>('');

  const { totalSteps, isAllConfigured, setIsLoadingShipping } =
    useProductConfigurationStore();
  const { isMobile } = useLayout();

  const {
    isLoading,
    shippingQuote,
    fetchShipping,
    findAndSelectEconomicShipping,
    error,
  } = useSimulateShippingHooks();

  const handleChange = (e: FormEvent<HTMLDivElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setZipCode(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      fetchShipping({ token, zipCode });
    }
  };

  const handleBlur = (e: FormEvent<HTMLDivElement>) => {
    const value = (e.target as HTMLInputElement).value;
    setZipCode(value);

    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'insert_zip_code',
    });

    if (value.length === 9) {
      fetchShipping({ token, zipCode });
    }
  };

  const handleErrorMessage = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { status, data } = (error as any).response;

    if (status !== 500) {
      return <FsFlag type={FlagType.Warning}>{data.message}</FsFlag>;
    }

    return (
      <FsFlag type={FlagType.Negative}>
        Não conseguimos calcular o frete no momento devido a uma instabilidade
        no sistema. Por favor, tente novamente em alguns minutos.
      </FsFlag>
    );
  };

  useEffect(() => {
    fetchShipping({ token, zipCode });
    // eslint-disable-next-line
  }, [token, fetchShipping]);

  useEffect(() => {
    findAndSelectEconomicShipping({ data: shippingQuote });
  }, [shippingQuote, findAndSelectEconomicShipping]);

  useEffect(() => {
    setIsLoadingShipping(isLoading);
  }, [setIsLoadingShipping, isLoading]);

  return (
    <S.Container ref={ref}>
      <FsHeading
        size={isMobile ? HeadingSize.MD : HeadingSize.LG}
        tag={HeadingTag.H2}
      >
        Simular frete
      </FsHeading>
      <FsDescription>
        Etapa {totalSteps} de {totalSteps}
      </FsDescription>
      <S.Content>
        <FsListContent
          type={isMobile ? ListContentType.Single : ListContentType.IconShape}
          icon={'DeliveryLine'}
          heading="Estimativa de frete e prazo"
          labelLeft="Esta é uma simulação de frete. O custo final será exibido no carrinho, com base nos produtos selecionados. O prazo de entrega começa a ser contado após você enviar suas artes."
          showTag={false}
          showContentRight={false}
          lastItem
        />
        <S.InputWrapper>
          <FsInputText
            showButton
            label="Insira o CEP"
            placeholder="00000-000"
            buttonLabel="Aplicar"
            maskType="cep"
            value={zipCode}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onClick={() => fetchShipping({ token, zipCode })}
            disabled={!isAllConfigured}
            buttonMiniProps={{
              type: 'button',
              disabled: zipCode.length !== 9 || isLoading,
            }}
            style={{
              width: isMobile ? '100%' : '45%',
            }}
            data-testid="pd-simulate-shipping"
          />
          {isLoading && (
            <>
              <FsSkeleton />
              <FsSkeleton />
              <FsSkeleton />
            </>
          )}
          {!error && shippingQuote?.length && (
            <ShippingList shippingQuote={shippingQuote} />
          )}

          {(error as AxiosResponse) && handleErrorMessage()}
        </S.InputWrapper>
      </S.Content>
    </S.Container>
  );
});
