import { FsDescription } from '@printi/ds-offset-react-core';
import { Characteristic } from '@mf/common/repositories/bff/product';
import { Topic } from '../Topic';
import * as S from './Characteristics.styles';

interface CharacteristicsProps {
  characteristics?: Characteristic[];
}

export const Characteristics = ({ characteristics }: CharacteristicsProps) => {
  return (
    <S.Container>
      <FsDescription>Características</FsDescription>
      <S.TopicGroup>
        {characteristics?.map((characteristic) => (
          <Topic
            key={characteristic.id}
            text={characteristic.Texto}
            tag={characteristic?.Tag}
          />
        ))}
      </S.TopicGroup>
    </S.Container>
  );
};
