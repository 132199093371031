import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;
`;

export const Content = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
  align-self: stretch;
`;

export const Heading = styled.div`
  display: flex;
  padding-bottom: var(--spacing-4x);
  align-items: center;
  gap: var(--spacing-3x);
  align-self: stretch;
`;
