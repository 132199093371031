/* eslint-disable import/order */
import {
  ButtonSize,
  FsButton,
  FsInputText,
  HeadingTag,
  ModalType,
} from '@printi/ds-offset-react-core';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import * as S from './ModalCustomFormat.styles';
import { useModalCustomFormatHooks } from './ModalCustomFormat.hooks';
import { TCustomFormat } from '@/services/product/types';

interface ModalCustomFormatProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  customFormat?: TCustomFormat;
}

export const ModalCustomFormat = ({
  isOpen,
  setOpen,
  customFormat,
}: ModalCustomFormatProps) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);

  const {
    validWidth,
    validHeight,
    supportText,
    handleConfirm,
    disabledConfirmButton,
  } = useModalCustomFormatHooks({
    customFormat,
  });

  const onConfirm = () => {
    handleConfirm({ width, height });
    setOpen(false);
  };

  const handleChange = (
    e: FormEvent<HTMLDivElement>,
    type: 'width' | 'height',
  ) => {
    let value = parseFloat((e.target as HTMLInputElement).value);

    if (Number.isNaN(value)) {
      value = 0;
    }

    if (type === 'width') {
      setWidth(value);
    } else {
      setHeight(value);
    }
  };

  useEffect(() => {
    setWidth(customFormat?.width?.min || 0);
  }, [customFormat?.width?.min]);

  useEffect(() => {
    setHeight(customFormat?.height?.min || 0);
  }, [customFormat?.height?.min]);

  return (
    <S.Modal
      heading="Formato personalizado"
      headingProps={{
        tag: HeadingTag.SPAN,
      }}
      isOpen={isOpen}
      type={ModalType.Slot}
      fixedBarActionSlot={
        <FsButton
          size={ButtonSize.LG}
          disabled={disabledConfirmButton({ height, width })}
          onClick={onConfirm}
        >
          Confirmar
        </FsButton>
      }
      onClose={() => setOpen(false)}
    >
      <S.Content>
        <S.Slot>
          <S.InputsWrapper>
            <FsInputText
              label="Largura (cm)"
              placeholder="Insira uma largura"
              type="number"
              inputProps={{
                type: 'number',
              }}
              value={width.toString()}
              onChange={(e) => handleChange(e, 'width')}
              error={!!validWidth(width)}
              supportText={validWidth(width) || supportText('width')}
            />
            <FsInputText
              label="Altura (cm)"
              placeholder="Insira uma altura"
              type="number"
              inputProps={{
                type: 'number',
              }}
              value={height.toString()}
              onChange={(e) => handleChange(e, 'height')}
              error={!!validHeight(height)}
              supportText={validHeight(height) || supportText('height')}
            />
          </S.InputsWrapper>
        </S.Slot>
      </S.Content>
    </S.Modal>
  );
};
