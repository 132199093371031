import { FsListSelect } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const ListSelect = styled(FsListSelect)`
  &:hover {
    cursor: pointer;
  }
`;

export const PriceDescription = styled.span`
  display: flex;
  align-items: baseline;
  gap: 4px;
  align-self: stretch;
`;

export const Heading = styled.div`
  display: flex;
  align-items: flex-end;
  gap: var(--shape-spacing-1x, 8px);
  align-self: stretch;
`;
