/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  ButtonSecondarySize,
  ButtonSize,
  FsButton,
  FsButtonIcon,
  FsDivider,
  FsHeading,
  FsLink,
  FsListContent,
  FsListContentGhost,
  FsParagraph,
  FsSkeleton,
  HeadingSize,
  ListContentType,
} from '@printi/ds-offset-react-core';
import { TQuantity } from '@mf/common/store/product-configuration';
import { RefObject, useContext, useState } from 'react';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as S from './FixedBar.styles';
import { TProduct } from '@/services/product/types';
import { CartContext } from '@mf/common/contexts/cart/CartContext';
import { CartContextType } from '@mf/common/contexts/cart/CartContext.types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';

interface FixedBarProps extends TQuantity {
  isLoading?: boolean;
  economicShippingPrice?: number;
  product?: TProduct;
  shippingRef: RefObject<HTMLDivElement>;
  configurationRef: RefObject<HTMLDivElement>;
  isLoadingShipping?: boolean;
}

export const FixedBar = ({
  token,
  price,
  quantity,
  unit_price,
  isLoading,
  shippingRef,
  configurationRef,
  economicShippingPrice,
  isLoadingShipping,
}: FixedBarProps) => {
  const { push } = useRouter();
  const { status } = useSession();
  const { offlineCartId, lastCartId, addItemCart, createOfflineCart } =
    useContext<CartContextType>(CartContext);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [addToCartLoading, setAddToCartLoading] = useState(false);

  const { invalidSummary } = useProductConfigurationStore();

  const handleToCart = () => {
    setAddToCartLoading(true);

    if (status === 'unauthenticated' && !offlineCartId) {
      const res = createOfflineCart();

      res.then((response) => {
        if (response !== null) {
          addItemCart(response.id, {
            token,
          });
        }
      }) as Promise<{
        customer_id: number;
        id: string;
        items: [];
      }>;
    }

    if (status === 'authenticated' && lastCartId) {
      addItemCart(lastCartId, {
        token,
      });
    }

    if (status === 'unauthenticated' && offlineCartId) {
      addItemCart(offlineCartId, {
        token,
      });
    }

    push('/carrinho');
  };

  const toggleExpanded = () => {
    setIsExpanded((state) => !state);
  };

  const scrollToConfiguration = () => {
    if (configurationRef?.current) {
      if (isExpanded) {
        setIsExpanded(false);
      }
      const elementPosition =
        configurationRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - 120, behavior: 'smooth' });
    }
  };

  const scrollToShipping = () => {
    if (shippingRef?.current) {
      setIsExpanded(false);

      const elementPosition =
        shippingRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - 120, behavior: 'smooth' });
    }
  };

  return (
    <S.Container isExpanded={isExpanded}>
      <S.Heading onClick={toggleExpanded}>
        <FsHeading size={HeadingSize.SM}>
          R${formatCurrency(invalidSummary ? 0 : price)}
        </FsHeading>
        <FsButtonIcon
          icon={isExpanded ? 'DropdownOpenLine' : 'DropdownCloseLine'}
        />
      </S.Heading>
      <S.Content isExpanded={isExpanded}>
        {isExpanded && (
          <>
            <S.ListContent>
              {isLoading ? (
                <>
                  <FsSkeleton />
                  <FsSkeleton />
                  <FsSkeleton />
                </>
              ) : (
                <>
                  <FsListContentGhost
                    textLeft="Quantidade"
                    textRight={invalidSummary ? '0' : quantity.toString()}
                  />
                  <FsListContentGhost
                    textLeft="Preço por unidade"
                    textRight={`R$${formatCurrency(invalidSummary ? 0 : unit_price)}`}
                  />
                  <FsListContentGhost
                    textLeft="Total"
                    textRight={`R$${formatCurrency(invalidSummary ? 0 : price)}`}
                  />
                </>
              )}
            </S.ListContent>
            <FsDivider style={{ width: '100%' }} />
            <S.ShippingWrapper>
              <FsParagraph
                style={{
                  color: 'var(--text-paragraph-color;)',
                }}
              >
                Estimativa de frete
              </FsParagraph>
              {isLoadingShipping ? (
                <>
                  <FsSkeleton />
                </>
              ) : (
                <>
                  {' '}
                  <FsLink onClick={scrollToShipping}>
                    {economicShippingPrice
                      ? `A partir de R$${formatCurrency(economicShippingPrice)}`
                      : 'Simular frete'}
                  </FsLink>
                </>
              )}
            </S.ShippingWrapper>
            <FsListContent
              type={ListContentType.Icon}
              lastItem
              showContentRight={false}
              showHeading={false}
              showLabelLeft
              showTag={false}
              icon={'PositiveLine'}
              labelLeft="Garantia Printi. Reimpressão grátis em caso de erros."
              style={{
                padding: 'var(--spacing-2x) 0 0 0',
              }}
            />
            <FsListContent
              type={ListContentType.Icon}
              lastItem
              showContentRight={false}
              showHeading={false}
              showLabelLeft
              showTag={false}
              icon={'ArtLine'}
              labelLeft="Designer Printi. Contrate montagem e ajustes de artes após concluir o seu pedido."
              style={{
                padding: '0',
              }}
            />
            <FsListContent
              type={ListContentType.Icon}
              lastItem
              showContentRight={false}
              showHeading={false}
              showLabelLeft
              showTag={false}
              icon={'PersonalizeLine'}
              labelLeft="Envio da arte. Faça upload dos seus arquivos de impressão antes ou depois de concluir o seu pedido."
              style={{
                padding: '0',
              }}
            />
          </>
        )}
      </S.Content>
      <S.Action>
        <S.ButtonGroup>
          <S.ButtonSecondary
            size={ButtonSecondarySize.LG}
            icon={'EditLine'}
            onClick={scrollToConfiguration}
          />
          <FsButton
            size={ButtonSize.LG}
            icon={'CheckoutAddLine'}
            style={{
              flex: '1 0 0',
            }}
            onClick={handleToCart}
            disabled={invalidSummary}
            loading={addToCartLoading}
          >
            Comprar
          </FsButton>
        </S.ButtonGroup>
      </S.Action>
    </S.Container>
  );
};
