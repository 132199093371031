'use client';

/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { RefObject, forwardRef, useEffect, useMemo } from 'react';
import { SimulateShipping } from '../SimulateShipping';
import * as S from './Configuration.styles';
import { Parts } from './Parts';
import { Quantities } from './Quantities';
import { useConfigurationHooks } from './Configuration.hooks';
import { TProduct } from '@/services/product/types';

interface ConfigurationProps {
  shippingRef: RefObject<HTMLDivElement>;
  productData?: TProduct;
}

// eslint-disable-next-line
export const Configuration = forwardRef<HTMLDivElement, ConfigurationProps>(
  ({ shippingRef, productData }, ref) => {
    const {
      denyRules,
      findDefaultCombination,
      checkOnlyOneInvisibleOption,
      checkOptionsWithDefaultCombination,
    } = useConfigurationHooks({
      final_product_id: productData?.final_product_id,
    });

    const {
      quantity,
      deniedOptions,
      setTotalSteps,
      customCombination,
      defaultCombination,
      updateDeniedOptions,
      setDefaultCombination,
      checkIfIsAllConfigured,
      setCustomQuantityOption,
      filteredInvisibleOptions,
      filterAnUpdateInvisibleByDenied,
    } = useProductConfigurationStore();

    const parts = useMemo(() => {
      return productData?.parts || [];
    }, [productData?.parts]);

    const finalProductId = useMemo(() => {
      return productData?.final_product_id;
    }, [productData?.final_product_id]);

    const defaultCombinations = useMemo(() => {
      return productData?.default_combinations;
    }, [productData?.default_combinations]);

    const customQuantity = useMemo(() => {
      return productData?.custom_quantity;
    }, [productData?.custom_quantity]);

    useEffect(() => {
      setTotalSteps(parts.length + 2);
    }, [setTotalSteps, parts]);

    useEffect(() => {
      updateDeniedOptions({ denyRules });
    }, [customCombination, denyRules, updateDeniedOptions]);

    useEffect(() => {
      filterAnUpdateInvisibleByDenied();
    }, [deniedOptions, filterAnUpdateInvisibleByDenied]);

    useEffect(() => {
      checkOnlyOneInvisibleOption({
        filteredInvisibleOptions,
        customCombination,
      });
      // eslint-disable-next-line
    }, [filteredInvisibleOptions, customCombination]);

    useEffect(() => {
      checkIfIsAllConfigured();
      setCustomQuantityOption(null);
    }, [customCombination, checkIfIsAllConfigured, setCustomQuantityOption]);

    useEffect(() => {
      const defaultCombination = findDefaultCombination({
        defaultCombinations,
      });
      setDefaultCombination(defaultCombination || null);
    }, [defaultCombinations, findDefaultCombination, setDefaultCombination]);

    useEffect(() => {
      checkOptionsWithDefaultCombination({
        customCombination,
        defaultCombination,
      });
      // eslint-disable-next-line
    }, [defaultCombination]);

    return (
      <S.Container ref={ref}>
        <Parts parts={parts} />
        <Quantities
          final_product_id={finalProductId}
          customQuantity={customQuantity}
        />
        <SimulateShipping token={quantity.token} ref={shippingRef} />
      </S.Container>
    );
  },
);
