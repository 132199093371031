import {
  DeniedOptions,
  TDefaultCombination,
  useProductConfigurationStore,
} from '@mf/common/store/product-configuration';
import { useCallback } from 'react';
import { TPartsAttributesOptions } from '@/services/product/types';

interface GetAvailableOptionsProps {
  partId: string;
  options: TPartsAttributesOptions[];
  attributeId: string;
  deniedOptions: DeniedOptions;
}

interface AutoSelectFirstAvailableOptionProps {
  partId: string;
  attributeId: string;
  availableOptions: TPartsAttributesOptions[];
}

interface ReorderOptionsProps {
  partId: string;
  options: TPartsAttributesOptions[];
  attributeId: string;
  defaultCombination: TDefaultCombination | null;
}

export const useAttributeHooks = () => {
  const { updateCustomCombination } = useProductConfigurationStore();

  const getAvailableOptions = ({
    partId,
    options,
    attributeId,
    deniedOptions,
  }: GetAvailableOptionsProps) => {
    return options.filter((option) => {
      const id = option.id;
      const notAvailable = deniedOptions?.[partId]?.[attributeId];

      if (notAvailable) {
        return !notAvailable.includes(id);
      }

      return true;
    });
  };

  const autoSelectFirstAvailableOption = useCallback(
    ({
      partId,
      attributeId,
      availableOptions,
    }: AutoSelectFirstAvailableOptionProps) => {
      const optionId = availableOptions[0]?.id;

      if (optionId) {
        updateCustomCombination({
          partId,
          optionId,
          attributeId,
        });
      }
    },
    [updateCustomCombination],
  );

  const reorderOptions = useCallback(
    ({
      options,
      attributeId,
      partId,
      defaultCombination,
    }: ReorderOptionsProps) => {
      if (!defaultCombination) {
        return;
      }

      const combination = {
        ...defaultCombination,
        parts: defaultCombination.parts.map((part) => ({
          ...part,
          options: {
            ...part.options,
          },
        })),
      };

      const listOptions = [...options];

      const part = combination.parts.find((part) => part.id === partId);

      if (!part) {
        return;
      }

      const selectedValue =
        part.options[attributeId as keyof typeof part.options];

      return listOptions.sort((a, b) => {
        if (a.id === selectedValue) {
          return -1;
        }
        if (b.id === selectedValue) {
          return 1;
        }
        return 0;
      });
    },
    [],
  );

  return {
    reorderOptions,
    getAvailableOptions,
    autoSelectFirstAvailableOption,
  };
};
