import { FsSkeleton } from '@printi/ds-offset-react-core';
import * as S from './LoadingQuantities.styles';

export const LoadingQuantities = () => {
  return (
    <S.Container>
      {Array.from({ length: 8 }).map((_, index) => (
        <FsSkeleton key={index} />
      ))}
    </S.Container>
  );
};
