import styled from 'styled-components';
import { media } from '@mf/common/utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  margin-bottom: var(--spacing-7x);
`;

export const Content = styled.div`
  display: grid;
  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;
  grid-template-columns: repeat(2, 1fr);

  ${media.lessThan('mobile')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
