/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  ButtonSize,
  FsButton,
  FsInputText,
  HeadingTag,
  ModalType,
} from '@printi/ds-offset-react-core';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import * as S from './ModalCustomQuantity.styles';
import { useModalCustomQuantityHooks } from './ModalCustomQuantity.hooks';
import { TCustomQuantity } from '@/services/product/types';

interface ModalCustomQuantityProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  customQuantity?: TCustomQuantity;
}

export const ModalCustomQuantity = ({
  isOpen,
  setOpen,
  customQuantity,
}: ModalCustomQuantityProps) => {
  const [quantity, setQuantity] = useState<number>(0);

  const { setCustomQuantityOption, deniedOptions } =
    useProductConfigurationStore();

  const { supportText, validQuantity } = useModalCustomQuantityHooks({
    customQuantity,
  });

  const onConfirm = () => {
    setCustomQuantityOption(quantity);
    setOpen(false);
  };

  const handleChange = (e: FormEvent<HTMLDivElement>) => {
    let value = parseFloat((e.target as HTMLInputElement).value);

    if (Number.isNaN(value)) {
      value = 0;
    }

    setQuantity(value);
  };

  useEffect(() => {
    if (!customQuantity) {
      return;
    }

    if (deniedOptions?.quantity) {
      const value = deniedOptions?.quantity?.values[0];
      const type = deniedOptions?.quantity?.values[1];

      if (type === '<') {
        setQuantity(Number(value));
      }
    } else {
      setQuantity(customQuantity.min);
    }
  }, [customQuantity, deniedOptions.quantity]);

  return (
    <S.Modal
      heading="Quantidade personalizada"
      headingProps={{
        tag: HeadingTag.SPAN,
      }}
      isOpen={isOpen}
      type={ModalType.Slot}
      fixedBarActionSlot={
        <FsButton
          size={ButtonSize.LG}
          disabled={!!validQuantity(quantity)}
          onClick={onConfirm}
        >
          Confirmar
        </FsButton>
      }
      onClose={() => setOpen(false)}
    >
      <S.Content>
        <S.Slot>
          <FsInputText
            label="Insira uma quantidade"
            type="number"
            inputProps={{
              type: 'number',
            }}
            value={quantity?.toString()}
            onChange={(e) => handleChange(e)}
            error={!!validQuantity(quantity)}
            supportText={validQuantity(quantity) || supportText()}
          />
        </S.Slot>
      </S.Content>
    </S.Modal>
  );
};
