import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5x);
  align-self: stretch;
`;

export const Title = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-half);
  align-self: stretch;
`;

export const ContentConfiguration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-5x);
  align-self: stretch;
`;
