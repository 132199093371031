import { TShippingQuoteResponse } from '@mf/checkout/repositories/bff/checkout/types';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { FsListContent } from '@printi/ds-offset-react-core';
import * as S from './ShippingList.styles';

interface ShippingListProps {
  shippingQuote?: TShippingQuoteResponse[];
}

export const ShippingList = ({ shippingQuote }: ShippingListProps) => {
  return (
    <S.Container>
      {shippingQuote?.map((shipping) => {
        let name = '';

        if (shipping.name === 'Agora') {
          name = 'Expressa';
        } else if (shipping.name === 'Expressa') {
          name = 'Rápida';
        } else {
          name = shipping.name;
        }

        return (
          <FsListContent
            key={shipping.token}
            heading={name}
            labelLeft={`${shipping.total_business_days} dias úteis`}
            labelRight="A partir de"
            description={`R$${formatCurrency(shipping.price)}`}
            showContentRight
            showButton={false}
            showTag={false}
            lastItem
          />
        );
      })}
    </S.Container>
  );
};
