import { FsTopSection, HeadingTag } from '@printi/ds-offset-react-core';
import * as S from './Resources.styles';
import { Templates } from './Templates';
import { ArtPreview } from './ArtPreview';

interface ResourceProps {
  finalProductId?: string;
}

export const Resources = ({ finalProductId }: ResourceProps) => {
  return (
    <S.Container>
      <FsTopSection
        heading="Recursos"
        showDescription={false}
        headingProps={{
          tag: HeadingTag.H2,
        }}
      />
      <S.Content>
        <Templates finalProductId={finalProductId} />
        <ArtPreview />
      </S.Content>
    </S.Container>
  );
};
