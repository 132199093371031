'use client';

/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { TQuantity } from '@mf/common/store/product-configuration';
import {
  ButtonSize,
  FsButton,
  FsDivider,
  FsHeading,
  FsLink,
  FsListContent,
  FsListContentGhost,
  FsParagraph,
  FsSkeleton,
  HeadingSize,
  HeadingTag,
  ListContentType,
} from '@printi/ds-offset-react-core';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as S from './Summary.styles';
import { ChosenConfiguration } from './ChosenConfiguration';
import { TParts } from '@/services/product/types';
import { TProduct } from '@/services/product/types';
import { RefObject, useContext, useState } from 'react';
import { CartContext } from '@mf/common/contexts/cart/CartContext';
import { CartContextType } from '@mf/common/contexts/cart/CartContext.types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/navigation';

interface SummaryProps extends TQuantity {
  parts?: TParts[];
  isLoading?: boolean;
  isLoadingShipping?: boolean;
  economicShippingPrice?: number;
  shippingRef: RefObject<HTMLDivElement>;
  product?: TProduct;
}

export const Summary = ({
  token,
  quantity,
  product,
  unit_price,
  price,
  parts,
  isLoading,
  shippingRef,
  isLoadingShipping,
  economicShippingPrice,
}: SummaryProps) => {
  const { push } = useRouter();
  const { status } = useSession();
  const { offlineCartId, lastCartId, addItemCart, createOfflineCart } =
    useContext<CartContextType>(CartContext);
  const { invalidSummary } = useProductConfigurationStore();

  const [addToCartLoading, setAddToCartLoading] = useState(false);

  const handleToCart = () => {
    setAddToCartLoading(true);

    const selectedQuantity = {
      token,
      quantity,
      product,
      unit_price,
      price,
    };

    if (status === 'unauthenticated' && !offlineCartId) {
      const res = createOfflineCart();

      res.then((response) => {
        if (response !== null) {
          addItemCart(response.id, {
            token,
          });
        }
      }) as Promise<{
        customer_id: number;
        id: string;
        items: [];
      }>;
    }

    if (status === 'unauthenticated' && offlineCartId) {
      addItemCart(offlineCartId, {
        token,
      });
    }

    if (status === 'authenticated' && lastCartId) {
      addItemCart(lastCartId, {
        token,
      });
    }

    window?.dataLayer?.push({ ecommerce: null });
    window?.dataLayer?.push({
      event: 'add_to_cart',
      event_name: 'add_to_cart',
      ecommerce: {
        currency: 'BRL',
        value: parseFloat(selectedQuantity.price.toFixed(2)),
        items: [
          {
            item_id: product?.final_product_id,
            item_name: product?.name,
            affiliation: 'Printi',
            coupon: '',
            discount: 0,
            index: 0,
            item_brand: 'Printi',
            // item_variant: product.selectedCombination.name,
            location_id: '',
            price: parseFloat(selectedQuantity.price.toFixed(2)),
            quantity: selectedQuantity.quantity,
          },
        ],
      },
    });

    push('/carrinho');
  };

  const scrollToShipping = () => {
    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'start_shipping_simulation',
    });

    if (shippingRef?.current) {
      const elementPosition =
        shippingRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - 120, behavior: 'smooth' });
    }
  };

  return (
    <S.Container>
      <FsHeading size={HeadingSize.SM} tag={HeadingTag.H2}>
        Resumo
      </FsHeading>
      <S.ListGroup>
        <S.ListContent>
          {isLoading ? (
            <>
              <FsSkeleton />
              <FsSkeleton />
              <FsSkeleton />
            </>
          ) : (
            <>
              <FsListContentGhost
                textLeft="Quantidade"
                textRight={invalidSummary ? '0' : quantity.toString()}
              />
              <FsListContentGhost
                textLeft="Preço por unidade"
                textRight={`R$${formatCurrency(invalidSummary ? 0 : unit_price)}`}
              />
              <FsListContentGhost
                textLeft="Total"
                textRight={`R$${formatCurrency(invalidSummary ? 0 : price)}`}
                bold
              />
            </>
          )}
        </S.ListContent>
      </S.ListGroup>
      <FsDivider style={{ width: '100%' }} />
      <S.ShippingWrapper>
        <FsParagraph
          style={{
            color: 'var(--text-paragraph-color;)',
          }}
        >
          Estimativa de frete
        </FsParagraph>
        {isLoadingShipping ? (
          <>
            <FsSkeleton />
          </>
        ) : (
          <>
            {' '}
            <FsLink onClick={scrollToShipping}>
              {economicShippingPrice
                ? `A partir de R$${formatCurrency(economicShippingPrice)}`
                : 'Simular frete'}
            </FsLink>
          </>
        )}
      </S.ShippingWrapper>
      <S.BuyWrapper>
        <FsButton
          size={ButtonSize.LG}
          icon={'CheckoutAddLine'}
          style={{
            width: '100%',
          }}
          onClick={handleToCart}
          disabled={invalidSummary}
          loading={addToCartLoading}
          data-testid="summary-buy-button"
        >
          Comprar
        </FsButton>
        <FsListContent
          type={ListContentType.Icon}
          lastItem
          showContentRight={false}
          showHeading={false}
          showLabelLeft
          showTag={false}
          icon={'PositiveLine'}
          labelLeft="Garantia Printi. Reimpressão grátis em caso de erros."
          style={{
            padding: 'var(--spacing-2x) 0 0 0',
          }}
        />
        <FsListContent
          type={ListContentType.Icon}
          lastItem
          showContentRight={false}
          showHeading={false}
          showLabelLeft
          showTag={false}
          icon={'ArtLine'}
          labelLeft="Designer Printi. Contrate montagem e ajustes de artes após concluir o seu pedido."
          style={{
            padding: '0',
          }}
        />
        <FsListContent
          type={ListContentType.Icon}
          lastItem
          showContentRight={false}
          showHeading={false}
          showLabelLeft
          showTag={false}
          icon={'PersonalizeLine'}
          labelLeft="Envio da arte. Faça upload dos seus arquivos de impressão antes ou depois de concluir o seu pedido."
          style={{
            padding: '0',
          }}
        />
      </S.BuyWrapper>
      <ChosenConfiguration parts={parts} />
    </S.Container>
  );
};
