/* eslint-disable import/order */
import {
  ButtonSize,
  FsButton,
  FsInputText,
  HeadingTag,
  ModalType,
} from '@printi/ds-offset-react-core';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import * as S from './ModalCustomPage.styles';
import { useModalCustomPageHooks } from './ModalCustomPage.hooks';
import { TCustomPage } from '@/services/product/types';

interface ModalCustomPageProps {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  customPage?: TCustomPage;
  attributeName: string;
}

export const ModalCustomPage = ({
  isOpen,
  setOpen,
  customPage,
  attributeName,
}: ModalCustomPageProps) => {
  const [pages, setPages] = useState<number>(0);

  const { validPages, supportText, handleConfirm } = useModalCustomPageHooks({
    customPage,
  });

  const onCofirm = () => {
    handleConfirm(pages);
    setOpen(false);
  };

  const handleChange = (e: FormEvent<HTMLDivElement>) => {
    let value = parseFloat((e.target as HTMLInputElement).value);

    if (Number.isNaN(value)) {
      value = 0;
    }

    setPages(value);
  };

  useEffect(() => {
    if (!customPage) {
      return;
    }

    setPages(customPage.min || 0);
  }, [customPage]);

  return (
    <S.Modal
      heading={`${attributeName} Personalizada`}
      headingProps={{
        tag: HeadingTag.SPAN,
      }}
      isOpen={isOpen}
      type={ModalType.Slot}
      fixedBarActionSlot={
        <FsButton
          size={ButtonSize.LG}
          disabled={!!validPages(pages)}
          onClick={onCofirm}
        >
          Confirmar
        </FsButton>
      }
      onClose={() => setOpen(false)}
    >
      <S.Content>
        <S.Slot>
          <FsInputText
            label="Insira uma quantidade"
            placeholder="Insira uma quantidade"
            type="number"
            inputProps={{
              type: 'number',
            }}
            value={pages.toString()}
            onChange={(e) => handleChange(e)}
            error={!!validPages(pages)}
            supportText={validPages(pages) || supportText()}
          />
        </S.Slot>
      </S.Content>
    </S.Modal>
  );
};
