/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { useEffect } from 'react';
import { Part } from '../Part';
import { usePartsHooks } from './Parts.hooks';
import { TParts } from '@/services/product/types';

interface PartsProps {
  parts: TParts[];
}

export const Parts = ({ parts }: PartsProps) => {
  const { getTotalAttributes } = usePartsHooks();
  const { setTotalAttributes } = useProductConfigurationStore();

  useEffect(() => {
    const totalAttributes = getTotalAttributes({ parts });
    setTotalAttributes(totalAttributes);
    // eslint-disable-next-line
  }, [parts]);

  return (
    <>
      {parts.map((part, key) => (
        <Part key={part.id} step={key + 1} {...part} />
      ))}
    </>
  );
};
