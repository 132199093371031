import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: var(--size-3x);
  padding: var(--spacing-none);
  align-items: center;
  gap: var(--spacing-1x);
  align-self: stretch;
`;
