import styled from 'styled-components';
import { media } from '@mf/common/utils/styles';

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacing-3x);

  align-items: flex-start;
  align-content: flex-start;
  align-self: stretch;

  ${media.lessThan('mobile')} {
    grid-template-columns: repeat(1, 1fr);
  }
`;
