import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  FsButtonMini,
  FsHeading,
  FsParagraph,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useState } from 'react';
import { DrawerTemplates } from '../DrawerTemplates';
import * as S from './Templates.styles';

interface TemplateProps {
  finalProductId?: string;
}

export const Templates = ({ finalProductId }: TemplateProps) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { isAllConfigured } = useProductConfigurationStore();

  return (
    <S.Container>
      <S.Content>
        <FsHeading size={HeadingSize.SM} tag={HeadingTag.H3}>
          Gabaritos
        </FsHeading>
        <S.TextWrapper>
          <FsParagraph>
            Baixe nossos gabaritos oficiais em PDF, Photoshop, Illustrator e
            Canva.
          </FsParagraph>
          <FsButtonMini
            disabled={!isAllConfigured} //TODO  has custom property
            onClick={() => {
              setOpenDrawer(true);
              window.dataLayer.push({
                event: 'genericEvent',
                event_name: 'click_download_gabarito',
              });
            }}
          >
            Ver gabaritos
          </FsButtonMini>
        </S.TextWrapper>
      </S.Content>
      <DrawerTemplates
        isOpen={openDrawer}
        finalProductId={finalProductId}
        setOpen={setOpenDrawer}
      />
    </S.Container>
  );
};
