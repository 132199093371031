import { FsButtonSecondary } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div<{ isExpanded: boolean }>`
  display: flex;
  padding: var(--spacing-2x);
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ isExpanded }) =>
    isExpanded ? 'var(--spacing-3x)' : 'var(--spacing-1_5x)'};

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: var(--z-index-fixed);
  transition: 0.4s linear;

  border-top: var(--default-border-width) solid var(--elements-border-color);
  background: var(--elements-bg-color-01);
`;

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-1x);
  flex: 1 0 0;
`;

export const ButtonSecondary = styled(FsButtonSecondary)`
  min-width: var(--size-8x);
  height: var(--size-8x);
  width: var(--size-8x);
  padding: 0px;

  div {
    padding-right: 0px;
  }
`;

export const Content = styled.div<{ isExpanded: boolean }>`
  height: ${({ isExpanded }) => (isExpanded ? '400px' : '0px')};
  transition: 0.4s linear;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3x);
`;

export const ListContent = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1x);
  align-self: stretch;

  > div {
    width: 100%;
  }

  > div {
    &[type='square'] {
      margin-bottom: 10px;
      height: 14px !important;
      width: 100% !important;
    }
  }
`;

export const ShippingWrapper = styled.div`
  display: flex;
  height: var(--size-3x);
  padding: var(--spacing-none);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > div {
    &[type='square'] {
      height: 38px !important;
      width: 140px;
    }
  }
`;
