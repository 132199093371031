/* eslint-disable import/order */
import { Quantity } from '../Quantity';
import * as S from './QuantitiesList.styles';
import { TDefaultCombinationsQuantities } from '@/services/product/types';

interface QuantitiesListProps {
  quantities?: TDefaultCombinationsQuantities[];
}

export const QuantitiesList = ({ quantities }: QuantitiesListProps) => {
  return (
    <S.Container>
      {quantities?.map((quantity) => (
        <Quantity key={quantity.token} {...quantity} />
      ))}
    </S.Container>
  );
};
