import { TCombination } from '@mf/common/store/product-configuration';
import { TPartsAttributesOptions } from '@/services/product/types';

interface GetOptionTypeProps {
  options: TPartsAttributesOptions[];
}

interface GetSelectedProps {
  id: string;
  partId: string;
  attributeId: string;
  customCombination: TCombination;
}

export const useOptionsHooks = () => {
  const getOptionType = ({ options }: GetOptionTypeProps) => {
    const hasImageOption = options.some((option) => !!option.image);

    if (hasImageOption) {
      return 'image';
    }

    return 'text';
  };

  const getSelected = ({
    id,
    partId,
    attributeId,
    customCombination,
  }: GetSelectedProps) => {
    const parts = customCombination.parts;
    const partIndex = parts.findIndex((part) => part.id === partId);

    if (partIndex !== -1) {
      const part = parts[partIndex];
      const attribute = attributeId as keyof typeof part;

      return part.options[attribute as keyof typeof part.options] === id;
    }
    return false;
  };

  return {
    getSelected,
    getOptionType,
  };
};
