/* eslint-disable import/order */
import { forwardRef } from 'react';
import { CardSelect } from '../CardSelect';
import { TextSelect } from '../TextSelect/TextSelect';
import { useOptionHooks } from './Option.hooks';
import { TPartsAttributesOptions } from '@/services/product/types';

interface OptionProps extends TPartsAttributesOptions {
  partId: string;
  attributeId: string;
  type?: 'image' | 'text';
  selected: boolean;
}

// eslint-disable-next-line
export const Option = forwardRef<HTMLDivElement, OptionProps>(
  ({ type = 'text', image = '', id, name, partId, attributeId, selected }) => {
    const { handleSelectOption } = useOptionHooks();

    return (
      <>
        {type === 'image' ? (
          <CardSelect
            onClick={() =>
              handleSelectOption({
                attributeId,
                partId,
                optionId: id,
              })
            }
            selected={selected}
            heading={name}
            image={image}
            value={id}
          />
        ) : (
          <TextSelect
            onClick={() =>
              handleSelectOption({
                attributeId,
                partId,
                optionId: id,
              })
            }
            selected={selected}
            optionName={name}
            value={id}
          />
        )}
      </>
    );
  },
);
