/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  FsDescription,
  FsRadioButton,
  FsTag,
  ListSelectType,
  TagType,
} from '@printi/ds-offset-react-core';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as S from './Quantity.styles';
import { TDefaultCombinationsQuantities } from '@/services/product/types';

interface QuantityProps extends TDefaultCombinationsQuantities {}

export const Quantity = ({
  price,
  quantity,
  token,
  unit_price,
  discount,
}: QuantityProps) => {
  const { selectQuantity, quantity: selectedQuantity } =
    useProductConfigurationStore();

  const handleSelectQuantity = () => {
    selectQuantity({
      price,
      quantity,
      token,
      unit_price,
    });
  };

  const selected = selectedQuantity?.token === token;
  const heading = `${quantity.toString()} ${quantity > 1 ? 'unidades' : 'unidade'}`;

  return (
    <S.ListSelect
      type={ListSelectType.Text}
      heading={
        <S.Heading>
          <label>{heading}</label>
          {discount && (
            <FsTag type={TagType.Positive} label={`economize ${discount}`} />
          )}
        </S.Heading>
      }
      showDescription
      description={
        <S.PriceDescription>
          <FsDescription
            as="span"
            style={{
              color: 'var(--text-label-color)',
              fontSize: '14px',
              fontWeight: 500,
            }}
          >
            R${formatCurrency(price)}
          </FsDescription>
          <FsDescription
            as="span"
            style={{
              fontSize: '12px',
            }}
          >
            R$ {formatCurrency(unit_price)} / unid
          </FsDescription>
        </S.PriceDescription>
      }
      selected={selected}
      onClick={handleSelectQuantity}
    >
      <>
        <FsRadioButton
          value={''}
          selected={selected}
          inputProps={{
            onChange: () => {
              handleSelectQuantity();
            },
          }}
        />
      </>
    </S.ListSelect>
  );
};
