/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { Option } from '../Option';
import * as S from './Options.styles';
import { useOptionsHooks } from './Options.hooks';
import { TPartsAttributesOptions } from '@/services/product/types';
import { FsSkeleton } from '@printi/ds-offset-react-core';

interface OptionsProps {
  options: TPartsAttributesOptions[];
  partId: string;
  attributeId: string;
}

export const Options = ({ options, partId, attributeId }: OptionsProps) => {
  const { customCombination, isLoadingQuantities, lastSelectedAttribute } =
    useProductConfigurationStore();
  const { getOptionType, getSelected } = useOptionsHooks();

  const optionType = getOptionType({ options });

  const loadingSkeleton =
    isLoadingQuantities && attributeId !== lastSelectedAttribute;

  return (
    <S.Container>
      {loadingSkeleton ? (
        <>
          <FsSkeleton />
          <FsSkeleton />
          <FsSkeleton />
        </>
      ) : (
        <>
          {options.map((option) => {
            const id = option.id;
            const selected = getSelected({
              id,
              partId,
              attributeId,
              customCombination,
            });

            return (
              <Option
                key={option.id}
                type={optionType}
                partId={partId}
                selected={selected}
                attributeId={attributeId}
                {...option}
              />
            );
          })}
        </>
      )}
    </S.Container>
  );
};
