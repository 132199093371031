import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  TAttributeOptions,
  TCustomFormat,
  TCustomPage,
  TPartsAttributes,
  TPartsAttributesOptions,
} from '@/services/product/types';

interface GetOptionsProps {
  attributeId: string;
  attributesOptions: TAttributeOptions;
}

interface VerifyIsCustomPage {
  attributeId: string;
  customPage?: TCustomPage;
}

interface VerifyIsCustomFormat {
  attributeId: string;
  customFormat?: TCustomFormat;
}

interface CheckAndUpdateInvisibleOptionsProps {
  partId: string;
  attributeId: string;
  attributeOptions: TPartsAttributesOptions[];
}

interface VerifyInvisibleOptionsProps {
  partId: string;
  attributes: TPartsAttributes[];
  attributesOptions: TAttributeOptions;
}

export const useAttributesHooks = () => {
  const { updateInvisibleOptions, updateCustomCombination } =
    useProductConfigurationStore();

  const getOptions = ({ attributeId, attributesOptions }: GetOptionsProps) => {
    const key = attributeId as keyof typeof attributesOptions;
    const options = attributesOptions[key];

    if (options) {
      return options;
    }

    return [];
  };

  const verifyIsCustomPage = ({
    attributeId,
    customPage,
  }: VerifyIsCustomPage) => {
    return attributeId === 'page' && customPage?.allow;
  };

  const verifyIsCustomFormat = ({
    attributeId,
    customFormat,
  }: VerifyIsCustomFormat) => {
    return attributeId === 'format' && customFormat?.allow;
  };

  const verifyInvisibleOptions = ({
    partId,
    attributes,
    attributesOptions,
  }: VerifyInvisibleOptionsProps) => {
    attributes.forEach((attribute) => {
      const attributeId = attribute.id;
      const isVisible = attribute.visible;
      const options = getOptions({ attributeId, attributesOptions });

      if (!isVisible) {
        checkOrUpdateInvisibleOptions({
          partId,
          attributeId,
          attributeOptions: options,
        });
      }
    });
  };

  const checkOrUpdateInvisibleOptions = (
    props: CheckAndUpdateInvisibleOptionsProps,
  ) => {
    const { attributeOptions, partId, attributeId } = props;
    const optionsLength = attributeOptions.length;

    if (optionsLength === 1) {
      const optionId = attributeOptions[0].id;

      updateCustomCombination({
        optionId,
        partId,
        attributeId,
      });
    } else if (optionsLength > 1) {
      updateInvisibleOptions(props);
    }
  };
  return {
    getOptions,
    verifyIsCustomPage,
    verifyIsCustomFormat,
    verifyInvisibleOptions,
    checkOrUpdateInvisibleOptions,
  };
};
