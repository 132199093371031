import styled from 'styled-components';

export const Container = styled.div`
  width: auto;
  display: grid;
  grid-template-columns: 7fr 4fr;
  padding: 0px var(--spacing-none) 0 var(--spacing-none);
  flex-direction: row;
  align-items: flex-start;
  gap: var(--spacing-8x);
  align-self: stretch;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
export const ContentContainer = styled.div`
  position: relative;
`;

export const TextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const Paragraph = styled.div<{ showMore: boolean }>(
  ({ showMore }) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${showMore ? 'unset' : '3'};
    -webkit-box-orient: vertical;
    cursor: pointer;
    transition: -webkit-line-clamp 0.5s;
`,
);

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-3x);
  flex: 1 0 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-1x);
  align-self: stretch;
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  gap: var(--spacing-1_5x);
  align-self: stretch;
  flex-wrap: wrap;

  > div {
    width: auto;
  }
`;
