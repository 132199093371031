import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--spacing-3x);

  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;

  > div {
    &[type='square'] {
      width: -webkit-fill-available;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
