import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
`;

export const Content = styled.div`
  margin-top: var(--spacing-4x);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4x);
  align-self: stretch;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: var(--spacing-3x);
  align-self: stretch;

  > div {
    &[type='square'] {
      height: 70px !important;
      width: 100% !important;
    }
  }
`;
