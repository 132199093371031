/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  ButtonSecondarySize,
  FsButtonMini,
  FsButtonSecondary,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useEffect, useMemo, useState } from 'react';
import { Options } from '../Options';
import { ModalCustomFormat } from '../ModalCustomFormat';
import { ModalCustomPage } from '../ModalCustomPage';
import * as S from './Attribute.styles';
import { useAttributeHooks } from './Attribute.hooks';
import {
  TCustomFormat,
  TCustomPage,
  TPartsAttributes,
  TPartsAttributesOptions,
} from '@/services/product/types';

interface AttributeProps extends TPartsAttributes {
  partId: string;
  options: TPartsAttributesOptions[];
  customPage?: TCustomPage;
  customFormat?: TCustomFormat;
  isCustomPage?: boolean;
  isCustomFormat?: boolean;
}

export const Attribute = ({
  id,
  name,
  partId,
  options,
  customPage,
  customFormat,
  isCustomPage,
  isCustomFormat,
}: AttributeProps) => {
  const [openCustomFormat, setOpenCustomFormat] = useState<boolean>(false);
  const [openCustomPage, setOpenCustomPage] = useState<boolean>(false);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const { isMobile } = useLayout();

  const hasCustomOption = isCustomFormat || isCustomPage;

  const {
    deniedOptions,
    customPageOption,
    customCombination,
    customFormatOption,
    defaultCombination,
    updateCustomCombination,
    checkIfHasAttributeValidOption,
  } = useProductConfigurationStore();

  const {
    reorderOptions,
    getAvailableOptions,
    autoSelectFirstAvailableOption,
  } = useAttributeHooks();

  const openCustom = () => {
    if (isCustomFormat) {
      setOpenCustomFormat(true);
      window.dataLayer.push({
        event: 'genericEvent',
        event_name: 'start_personalization_format',
      });
    } else if (isCustomPage) {
      setOpenCustomPage(true);
      window.dataLayer.push({
        event: 'genericEvent',
        event_name: 'start_personalization_page',
      });
    }
  };

  const availableOptions = useMemo(() => {
    const available = getAvailableOptions({
      partId,
      options,
      deniedOptions,
      attributeId: id,
    });

    if (!defaultCombination) {
      return available;
    }

    const optionsByDefaultCombination = reorderOptions({
      options: available,
      attributeId: id,
      partId,
      defaultCombination,
    });

    if (!optionsByDefaultCombination) {
      return available;
    }

    // TODO in getAvailableOptions fn
    if (customFormatOption && isCustomFormat) {
      optionsByDefaultCombination.unshift(customFormatOption);
    }

    if (customPageOption && isCustomPage) {
      optionsByDefaultCombination.unshift(customPageOption);
    }

    return optionsByDefaultCombination;
  }, [
    id,
    partId,
    options,
    isCustomPage,
    isCustomFormat,
    deniedOptions,
    customPageOption,
    customFormatOption,
    getAvailableOptions,
    defaultCombination,
    reorderOptions,
  ]);

  const showViewMoreButton = availableOptions.length > 3;

  const filteredOptions = useMemo(() => {
    if (viewMore) {
      return availableOptions;
    }

    return availableOptions.slice(0, 3);
  }, [availableOptions, viewMore]);

  useEffect(() => {
    const isValidOption = checkIfHasAttributeValidOption({
      customCombination,
      deniedOptions,
      searchParams: {
        partId,
        attributeId: id,
      },
    });

    if (!isValidOption) {
      autoSelectFirstAvailableOption({
        availableOptions,
        attributeId: id,
        partId,
      });
    }
  }, [
    id,
    partId,
    deniedOptions,
    availableOptions,
    customCombination,
    checkIfHasAttributeValidOption,
    autoSelectFirstAvailableOption,
  ]);

  //TODO make fns
  useEffect(() => {
    if (!customFormatOption || !isCustomFormat) {
      return;
    }
    updateCustomCombination({
      attributeId: id,
      optionId: customFormatOption.id,
      partId,
    });
  }, [isCustomFormat, id, partId, customFormatOption, updateCustomCombination]);

  useEffect(() => {
    if (!customPageOption || !isCustomPage) {
      return;
    }
    updateCustomCombination({
      attributeId: id,
      optionId: customPageOption.id,
      partId,
    });
  }, [id, isCustomPage, partId, customPageOption, updateCustomCombination]);

  return (
    <S.Container>
      <S.Content>
        <S.Heading>
          <FsHeading
            size={HeadingSize.SM}
            style={{
              flex: '1 0 0',
            }}
            tag={HeadingTag.H3}
          >
            {name}
          </FsHeading>
          {hasCustomOption && (
            <>
              {isMobile ? (
                <FsButtonMini
                  onClick={openCustom}
                  data-testid="pd-customization-btn"
                >
                  Personalizar
                </FsButtonMini>
              ) : (
                <FsButtonSecondary
                  size={ButtonSecondarySize.LG}
                  icon={'EditLine'}
                  onClick={openCustom}
                  data-testid="pd-customization-btn"
                >
                  Personalizar
                </FsButtonSecondary>
              )}
            </>
          )}
        </S.Heading>
        <Options options={filteredOptions} partId={partId} attributeId={id} />
      </S.Content>
      {showViewMoreButton && (
        <FsButtonMini
          onClick={() => setViewMore((state) => !state)}
          data-testid="pd-attr-see-more"
        >
          {viewMore ? 'Ver menos' : 'Ver mais'}
        </FsButtonMini>
      )}
      {isCustomFormat && (
        <ModalCustomFormat
          isOpen={openCustomFormat}
          setOpen={setOpenCustomFormat}
          customFormat={customFormat}
        />
      )}
      {isCustomPage && (
        <ModalCustomPage
          attributeName={name}
          isOpen={openCustomPage}
          setOpen={setOpenCustomPage}
          customPage={customPage}
        />
      )}
    </S.Container>
  );
};
