'use client';

/* eslint-disable import/order */
import {
  CurrencyPosition,
  CurrencySize,
  FsBreadcrumb,
  FsBreadcrumbItem,
  FsCurrency,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { TQuantity } from '@mf/common/store/product-configuration';
import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import * as S from './Overview.styles';
import { Gallery } from './Gallery';
import { ShortDescription } from './ShortDescription';
import { cmsProduct } from '@mf/common/repositories/bff/product/index';
import { Characteristics } from './Characteristics';
import { TProduct } from '@/services/product/types';
import { RefObject } from 'react';

interface OverviewProps extends TQuantity {
  slug: string;
  productData?: TProduct;
  specificationsRef: RefObject<HTMLDivElement>;
}

export const Overview = ({
  slug,
  price,
  unit_price,
  quantity,
  productData,
  specificationsRef,
}: OverviewProps) => {
  const { useCharacteristics, useProductData } = cmsProduct({
    baseUrl: process.env.BFF_API_BASE_URL,
  });

  const { data: characteristics } = useCharacteristics({
    slug: slug,
  });
  const { data: cmsProductData } = useProductData({
    slug: slug,
  });

  const bffUnitPrice = formatCurrency(
    (productData?.min_price?.value || 0) /
      (productData?.min_price?.quantity || 1),
    {
      precision: 2,
    },
  );

  const bffPrice = formatCurrency(productData?.min_price?.value || 0);

  const renderBreadcrumbItems = () => {
    return (
      <>
        <FsBreadcrumbItem href="/">Home</FsBreadcrumbItem>
        {cmsProductData?.main_category && (
          <FsBreadcrumbItem
            href={`/${cmsProductData?.main_category_slug}/`}
            onClick={() => {
              window.dataLayer.push({
                event: 'genericEvent',
                event_name: 'click_main_category_breadcrumb',
              });
            }}
          >
            {cmsProductData?.main_category}
          </FsBreadcrumbItem>
        )}
        <FsBreadcrumbItem href={`/${slug}/`} selected={true}>
          {productData?.name}
        </FsBreadcrumbItem>
      </>
    );
  };

  return (
    <>
      <FsBreadcrumb>{renderBreadcrumbItems()}</FsBreadcrumb>
      <S.Container>
        <Gallery
          name={productData?.name || ''}
          final_product_id={productData?.final_product_id || ''}
          banner={productData?.banner || { images: [], video: {} }}
        />
        <S.Content>
          <FsHeading size={HeadingSize.LG}>{productData?.name}</FsHeading>
          <S.PriceWrapper>
            <FsDescription>
              {quantity}
              {quantity > 1 ? ' unidades' : ' unidade'} por{' '}
            </FsDescription>
            <S.Price>
              <FsHeading size={HeadingSize.MD} tag={HeadingTag.SPAN}>
                R$ {price ? formatCurrency(price) : bffPrice}
              </FsHeading>
              <FsCurrency
                size={CurrencySize.SM}
                price={unit_price ? formatCurrency(unit_price) : bffUnitPrice}
                description="/ unid."
                descriptionPosition={CurrencyPosition.Right}
              />
            </S.Price>
          </S.PriceWrapper>
          <ShortDescription
            description={
              productData?.short_description || productData?.long_description
            }
            hasContent={!!cmsProductData?.content}
            specificationsRef={specificationsRef}
          />
          {!!characteristics?.length && (
            <Characteristics characteristics={characteristics} />
          )}
        </S.Content>
      </S.Container>
    </>
  );
};
