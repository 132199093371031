import { formatCurrency } from '@brazilian-utils/brazilian-utils';
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { TCustomQuantity } from '@/services/product/types';

interface UseModalCustomQuantityHooksProps {
  customQuantity?: TCustomQuantity;
}

export const useModalCustomQuantityHooks = ({
  customQuantity,
}: UseModalCustomQuantityHooksProps) => {
  const { deniedOptions } = useProductConfigurationStore();

  const supportText = () => {
    if (!deniedOptions?.quantity) {
      return `Escolha uma quantia entre ${customQuantity?.min} e ${formatCurrency(customQuantity?.max || 0, { precision: 0 })}`;
    }

    const type = deniedOptions?.quantity?.values[1];

    if (type === '>') {
      return `Escolha uma quantia entre ${customQuantity?.min} e ${deniedOptions?.quantity?.values[0]}`;
    } else {
      return `Escolha uma quantia entre ${deniedOptions?.quantity?.values[0]} e ${formatCurrency(customQuantity?.max || 0, { precision: 0 })}`;
    }
  };

  const isMultipleOf = (num: number, multipleOf: number) => {
    if (multipleOf === 0) {
      return false;
    }
    return num % multipleOf === 0;
  };

  const validQuantity = (quantity: number) => {
    if (!customQuantity) {
      return '';
    }

    if (deniedOptions?.quantity) {
      const value = deniedOptions?.quantity?.values[0];
      const type = deniedOptions?.quantity?.values[1];

      if (type === '>') {
        if (quantity > Number(value || 0)) {
          return 'Insira uma quantidade valida';
        }
      } else {
        if (quantity < Number(value || 0)) {
          return 'Insira uma quantidade valida';
        }
      }
    }

    const hasMultiple = customQuantity.multiple;

    if (quantity < customQuantity.min || quantity > customQuantity.max) {
      return 'Insira uma quantidade valida';
    } else if (hasMultiple && !isMultipleOf(quantity, hasMultiple)) {
      return `A quantia deve ser múltiplo de ${hasMultiple}`;
    }

    return '';
  };

  return {
    supportText,
    validQuantity,
  };
};
