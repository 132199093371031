import {
  FsDescription,
  FsIcon,
  FsTag,
  IconSize,
  TagType,
} from '@printi/ds-offset-react-core';
import * as S from './Topic.styles';

export const Topic = ({ text, tag }: { text: string; tag?: string | null }) => {
  return (
    <S.Container>
      <FsIcon size={IconSize.MD} icon={'CheckLine'} />
      <FsDescription>{text}</FsDescription>
      {tag && <FsTag label={tag} type={TagType.Promote} />}
    </S.Container>
  );
};
