import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { TCustomFormat } from '@/services/product/types';

interface UseModalCustomFormatHooksProps {
  customFormat?: TCustomFormat;
}

interface DisabledConfirmButtonProps {
  height: number;
  width: number;
}

interface HandleConfirmProps {
  height: number;
  width: number;
}

export const useModalCustomFormatHooks = ({
  customFormat,
}: UseModalCustomFormatHooksProps) => {
  const { setCustomFormatOption, setCustomFormat } =
    useProductConfigurationStore();

  const supportText = (type: 'width' | 'height') => {
    if (!customFormat) {
      return '';
    }

    if (type === 'width') {
      return `min: ${customFormat.width.min} , max: ${customFormat.width.max}`;
    } else if (type === 'height') {
      return `min: ${customFormat.height.min} , max: ${customFormat.height.max}`;
    }
  };

  const validWidth = (width: number) => {
    if (!customFormat?.width?.min || !customFormat?.width?.max) {
      return '';
    }

    if (width < customFormat?.width.min || width > customFormat?.width.max) {
      return 'Insira uma largura válida';
    }
  };

  const validHeight = (height: number) => {
    if (!customFormat?.height?.min || !customFormat?.height?.max) {
      return '';
    }

    if (
      height < customFormat?.height?.min ||
      height > customFormat?.height?.max
    ) {
      return 'Insira uma altura válida';
    }
  };

  const disabledConfirmButton = ({
    height,
    width,
  }: DisabledConfirmButtonProps) => {
    return !!validHeight(height) || !!validWidth(width);
  };

  const handleConfirm = ({ width, height }: HandleConfirmProps) => {
    const option = {
      id: `custom=${width}x${height}`,
      name: `Personalizado ${width} x ${height} cm`,
      image: '',
    };

    const customFormat = {
      isUsed: true,
      width,
      height,
    };

    setCustomFormatOption(option);
    setCustomFormat(customFormat);
  };

  return {
    validWidth,
    validHeight,
    supportText,
    handleConfirm,
    disabledConfirmButton,
  };
};
