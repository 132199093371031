/* eslint-disable import/order */
'use client';
import {
  TPartCombination,
  useProductConfigurationStore,
} from '@mf/common/store/product-configuration';
import {
  FsAccordion,
  FsParagraph,
  FsSkeleton,
  FsTag,
} from '@printi/ds-offset-react-core';
import * as S from './ChosenConfiguration.styles';
import { useChosenConfigurationHooks } from './ChosenConfiguration.hooks';
import { TParts } from '@/services/product/types';

interface ChosenConfigurationProps {
  parts?: TParts[];
}

export const ChosenConfiguration = ({ parts }: ChosenConfigurationProps) => {
  const { customCombination, isLoadingQuantities } =
    useProductConfigurationStore();
  const { getPartName, getOptionName } = useChosenConfigurationHooks();

  const renderPartConfiguration = (part: TPartCombination) => {
    return (
      <S.TagGroup>
        {Object.entries(part.options).map(([attributeId, option]) => {
          if (!option) {
            return <></>;
          }

          const optionName = getOptionName({
            parts,
            attributeId,
            partId: part.id,
            optionId: option,
          });

          return <FsTag key={option} label={optionName} />;
        })}
      </S.TagGroup>
    );
  };

  return (
    <FsAccordion
      id="chosenConfiration"
      index={0}
      label="Configurações escolhidas"
    >
      <S.Content>
        {isLoadingQuantities ? (
          <S.TagGroup>
            {Array.from({ length: 8 }).map((_, index) => (
              <FsSkeleton key={index} />
            ))}
          </S.TagGroup>
        ) : (
          <>
            {customCombination.parts.map((part) => {
              if (customCombination.parts.length > 1) {
                const partName = getPartName({ parts, partId: part.id });

                return (
                  <S.PartWrapper key={part.id}>
                    <FsParagraph key={part.id}>{partName}</FsParagraph>
                    {renderPartConfiguration(part)}
                  </S.PartWrapper>
                );
              } else {
                return renderPartConfiguration(part);
              }
            })}
          </>
        )}
      </S.Content>
    </FsAccordion>
  );
};
