import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: auto;

  > div {
    width: 100%;

    :last-child {
      min-width: 100px;
      span {
        width: 100%;
      }
    }
  }
`;
