import { TParts } from '@/services/product/types';

interface GetTotalAttributesProps {
  parts: TParts[];
}

export const usePartsHooks = () => {
  const getTotalAttributes = ({ parts }: GetTotalAttributesProps) => {
    const totalAttributes = parts.reduce((sum, part) => {
      const validAttributesCount = Object.keys(part.attributes_options).reduce(
        (count, key) => {
          const attribute = key as keyof typeof part.attributes_options;
          const options = part.attributes_options[attribute];

          return Array.isArray(options) && options.length > 0
            ? count + 1
            : count;
        },
        0,
      );

      return sum + validAttributesCount;
    }, 0);

    return totalAttributes;
  };

  return {
    getTotalAttributes,
  };
};
