import {
  TCombination,
  TDefaultCombinations,
  TInvisibleOptions,
  useProductConfigurationStore,
} from '@mf/common/store/product-configuration';
import productRules from '@mf/common/repositories/cdn/deny-rules/index';
import { useCallback } from 'react';

interface ConfigurationHooksProps {
  final_product_id?: string;
}

interface CheckOnlyOneInvisibleOptionProps {
  filteredInvisibleOptions: TInvisibleOptions;
  customCombination: TCombination;
}

interface FindDefaultCombinationsProps {
  defaultCombinations?: TDefaultCombinations[];
}

interface CheckOptionsWithDefaultCombinationProps {
  defaultCombination: TDefaultCombinations | null;
  customCombination: TCombination;
}

export const useConfigurationHooks = ({
  final_product_id,
}: ConfigurationHooksProps) => {
  const {
    setCustomCombination,
    checkIfIsTheSameOption,
    updateCustomCombination,
    filterAnUpdateInvisibleByDenied,
  } = useProductConfigurationStore();

  const { useProductDenyRules } = productRules({
    baseUrl: '/api',
  });

  const { data: denyRules } = useProductDenyRules({
    productId: final_product_id,
  });

  const filterInvisibleByDenied = () => {
    filterAnUpdateInvisibleByDenied();
  };

  const checkOnlyOneInvisibleOption = ({
    filteredInvisibleOptions,
    customCombination,
  }: CheckOnlyOneInvisibleOptionProps) => {
    const parts = filteredInvisibleOptions.parts;

    parts.forEach((part) => {
      const entries = Object.entries(part.options);
      const partId = part.id;

      entries.forEach(([attributeId, options]) => {
        if (options?.length === 1) {
          const optionId = options[0].id;

          const props = {
            attributeId,
            optionId,
            partId,
          };

          if (
            checkIfIsTheSameOption({
              customCombination,
              searchParams: props,
            })
          ) {
            return;
          }

          updateCustomCombination(props);
        }
      });
    });
  };

  const findDefaultCombination = useCallback(
    ({ defaultCombinations }: FindDefaultCombinationsProps) => {
      if (!defaultCombinations) {
        return;
      }

      const combinations = [...defaultCombinations];

      const cheaperCombination = (combinations || [])
        .sort((a, b) => a.quantities[0]?.price - b.quantities[0]?.price)
        .shift();

      return cheaperCombination;
    },
    [],
  );

  const checkOptionsWithDefaultCombination = ({
    defaultCombination,
    customCombination,
  }: CheckOptionsWithDefaultCombinationProps) => {
    if (!defaultCombination) {
      return;
    }

    const updatedCustomCombination = { ...customCombination };

    // deep copy
    const copyDefaultCombination = {
      ...defaultCombination,
      parts: defaultCombination.parts.map((part) => ({
        ...part,
        options: {
          ...part.options,
        },
      })),
    };

    const parts = copyDefaultCombination.parts.map((part) => part);
    updatedCustomCombination.parts = parts;

    setCustomCombination(updatedCustomCombination);
  };

  return {
    denyRules,
    findDefaultCombination,
    filterInvisibleByDenied,
    checkOnlyOneInvisibleOption,
    checkOptionsWithDefaultCombination,
  };
};
