import { AxiosInstance } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { api } from '@mf/common/config/axios/serviceApi';
import { TAxiosDefaultConfig } from '../../aecomStrapiCms/defaults/types';

export type Characteristic = {
  id: number;
  Texto: string;
  Tag: string | null;
};

export type Faq = {
  id: number;
  question: string;
  answer: string | React.ReactNode;
};

type GetCharacteristics = {
  data: {
    data: {
      attributes: {
        caracteristicas: Characteristic[];
      };
    }[];
  };
};

type GetProductFaq = {
  data: {
    data: {
      attributes: {
        faq: Faq[];
      };
    }[];
  };
};

export const cmsProduct = ({ baseUrl }: TAxiosDefaultConfig) => {
  const apiCms: AxiosInstance = api({ baseUrl });

  const getCharacteristics = async ({
    slug,
  }: {
    slug?: string;
  }): Promise<Characteristic[]> => {
    if (!slug) {
      return [];
    }

    const { data }: GetCharacteristics = await apiCms.get(
      `/v1/products?filters[slug][$eq]=${slug}&populate=caracteristicas`,
    );

    if (!data?.data?.length) {
      return [];
    }

    return data.data[0].attributes?.caracteristicas as Characteristic[];
  };

  const useCharacteristics = ({ slug }: { slug?: string }) => {
    return useQuery([`characteristics`, slug], () =>
      getCharacteristics({ slug }),
    );
  };

  const getProductData = async ({ slug }: { slug?: string }) => {
    if (!slug) {
      return [];
    }

    const { data } = await apiCms.get(
      `/v1/products?filters[slug][$eq]=${slug}&populate=*`,
    );

    if (!data?.data?.length) {
      return [];
    }

    return data.data[0].attributes;
  };

  const getProductFaq = async ({ slug }: { slug?: string }) => {
    if (!slug) {
      return [];
    }

    const { data }: GetProductFaq = await apiCms.get(
      `/v1/products?filters[slug][$eq]=${slug}&populate=faq`,
    );

    if (!data?.data?.length) {
      return [];
    }

    return data.data[0].attributes?.faq || [];
  };

  const useProductData = ({ slug }: { slug?: string }) => {
    return useQuery([`cms-product`, slug], () => getProductData({ slug }));
  };

  const useProductFaq = ({ slug }: { slug?: string }) => {
    return useQuery([`faq-product`, slug], () => getProductFaq({ slug }));
  };

  return {
    getCharacteristics,
    getProductData,
    useCharacteristics,
    useProductData,
    getProductFaq,
    useProductFaq,
  };
};
