import axios from 'axios';
import { Dispatch, SetStateAction } from 'react';

type Rate = {
  average: number;
  best: number;
  count: number;
  histogram: object;
};

interface ProductV2Hooks {
  final_product_id?: string;
}

interface FetchStarsProps {
  setStarsCount: Dispatch<SetStateAction<Rate>>;
}

export const useProductV2Hooks = ({ final_product_id }: ProductV2Hooks) => {
  const STORE_ID = String(process.env.TRUSTVOX_STORE_ID);
  const TOKEN = String(process.env.TRUSTVOX_TOKEN);
  const API_URL = String(process.env.TRUSTVOX_WIDGET_URL).concat(
    '?',
    new URLSearchParams({
      code: final_product_id || '',
      store_id: STORE_ID,
    }).toString(),
  );

  const fetchStars = async ({ setStarsCount }: FetchStarsProps) => {
    await axios
      .get(API_URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/vnd.trustvox-v2+json',
          Authorization: `token ${TOKEN}`,
        },
      })
      .then(function (response) {
        setStarsCount(() => ({
          ...response.data.rate,
        }));
      })
      .catch(function (error) {
        // eslint-disable-next-line
        console.error(error);
      });
  };

  return {
    fetchStars,
  };
};
