import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-1_5x);
  align-self: stretch;
`;

export const TagGroup = styled.div`
  height: auto;
  display: flex;
  min-width: 156px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;

  > div {
    &[type='square'] {
      height: 24px !important;
      width: 92px;
    }
  }
`;

export const PartWrapper = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1_5x);
`;
