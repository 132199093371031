import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import bffCheckout from '@mf/checkout/repositories/bff/checkout';
import { useCallback } from 'react';
import { TShippingQuoteResponse } from '@mf/checkout/repositories/bff/checkout/types';

interface FetchShippingProps {
  token: string;
  zipCode: string;
}

interface FindAndSelectEconomicShippingProps {
  data?: TShippingQuoteResponse[];
}

export const useSimulateShippingHooks = () => {
  const { setEconomicShipping } = useProductConfigurationStore();

  const { useShippingQuote } = bffCheckout({
    baseUrl: process.env.BFF_API_BASE_URL,
  });

  const {
    mutate: mutateShipping,
    isLoading,
    data: shippingQuote,
    error,
  } = useShippingQuote();

  const fetchShipping = useCallback(
    ({ token, zipCode }: FetchShippingProps) => {
      if (!token || !zipCode) {
        return;
      }

      if (zipCode.length !== 9) {
        return;
      }

      window.dataLayer.push({
        event: 'genericEvent',
        event_name: 'shipping_simulation_completed',
        event_parameter_1: '',
        event_parameter_2: '',
        event_parameter_3: zipCode,
      });

      const payload = {
        destination: {
          zip_code: zipCode,
        },
        items: [{ token }],
        simulate: true,
      };

      mutateShipping(payload);
    },
    [mutateShipping],
  );

  const findAndSelectEconomicShipping = useCallback(
    ({ data }: FindAndSelectEconomicShippingProps) => {
      const economic = data?.find((shipping) =>
        shipping.name?.includes('Econômica'),
      );

      if (economic) {
        setEconomicShipping(economic);
      }
    },
    [setEconomicShipping],
  );

  return {
    isLoading,
    shippingQuote,
    fetchShipping,
    error,
    findAndSelectEconomicShipping,
  };
};
