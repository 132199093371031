import { FsModal } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Modal = styled(FsModal)`
  z-index: 3000;

  :nth-child(2) {
    min-height: unset;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  padding: var(--spacing-5x);
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const Slot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const InputsWrapper = styled.div`
  display: flex;
  padding: var(--spacing-none);
  align-items: flex-start;
  gap: var(--spacing-2x);
  align-self: stretch;
`;
