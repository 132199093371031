/* eslint-disable import/order */
import {
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { useLayout } from '@mf/common/components/Media/Layout';
import { Attributes } from '../Attributes';
import * as S from './Part.styles';
import { TParts } from '@/services/product/types';

interface PartProps extends TParts {
  step: number;
}

export const Part = ({
  id,
  name,
  attributes,
  attributes_options,
  custom_format,
  custom_page,
  step,
}: PartProps) => {
  const { totalSteps } = useProductConfigurationStore();
  const { isMobile } = useLayout();

  return (
    <S.Container>
      <S.Title>
        <FsHeading
          size={isMobile ? HeadingSize.MD : HeadingSize.LG}
          tag={HeadingTag.H2}
        >
          {totalSteps > 3 ? name : 'Configuração'}
        </FsHeading>
        <FsDescription>
          Etapa {step} de {totalSteps}
        </FsDescription>
      </S.Title>
      <S.ContentConfiguration>
        <Attributes
          attributes={attributes}
          attributesOptions={attributes_options}
          customFormat={custom_format}
          customPage={custom_page}
          partId={id}
        />
      </S.ContentConfiguration>
    </S.Container>
  );
};
