import { useProductConfigurationStore } from '@mf/common/store/product-configuration';

interface HandleSelectOptionProps {
  optionId: string;
  partId: string;
  attributeId: string;
}

export const useOptionHooks = () => {
  const { updateCustomCombination, setLastSelectedAttribute } =
    useProductConfigurationStore();

  const handleSelectOption = (props: HandleSelectOptionProps) => {
    setLastSelectedAttribute(props.attributeId);
    updateCustomCombination(props);
  };

  return {
    handleSelectOption,
  };
};
