import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 318px;
  min-height: var(--shape-size-30x, 240px);
  padding: var(--spacing-4x) var(--spacing-5x);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;

  border-radius: var(--border-radius-md);
  border: var(--default-border-width) solid var(--elements-border-color);
`;

export const Content = styled.div`
  display: flex;
  height: 254px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  align-self: stretch;
`;

export const Frame = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-half);
  align-self: stretch;
`;
