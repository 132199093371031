'use client';

/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { Configuration } from '../Product/Configuration';
import { Overview } from '../Product/Overview';
import { Summary } from '../Product/Summary';
import productService from '@/services/product';
import { useLayout } from '@mf/common/components/Media/Layout';
import * as S from '@/screens/main/Product/Product.styles';
import { Resources } from '../Product/Resources';
import { CarouselRelatedProducts } from '@/components/main/CarouselRelatedProducts';
import { FixedBar } from '../Product/FixedBax';
import { HistogramRate } from '@/components/main/TrustvoxReviews';
import { cmsProduct } from '@mf/common/repositories/bff/product/index';
import { Specifications } from '../Product/Specifications';
import { useProductV2Hooks } from './ProductV2.hooks';

type Rate = {
  average: number;
  best: number;
  count: number;
  histogram: object;
};

interface TestProps {
  slug: string;
}

export function ProductV2({ slug }: TestProps) {
  const [starsCount, setStarsCount] = useState<Rate>({
    average: 0,
    best: 0,
    count: 0,
    histogram: {},
  });

  const { quantity, economicShipping, isLoadingShipping, isLoadingQuantities } =
    useProductConfigurationStore();

  const { useProduct } = productService({
    baseUrl: process.env.BFF_API_BASE_URL,
  });
  const { useProductData, useProductFaq } = cmsProduct({
    baseUrl: process.env.CMS_API_BASE_URL,
  });

  const { data: productData } = useProduct({ slug });
  const { data: cmsProductData } = useProductData({
    slug: slug,
  });
  const { data: productFaq } = useProductFaq({ slug: slug });
  const { fetchStars } = useProductV2Hooks({
    final_product_id: productData?.final_product_id,
  });

  const { isMobile } = useLayout();

  const parts = useMemo(() => {
    return productData?.parts;
  }, [productData?.parts]);

  const finalProductId = useMemo(() => {
    return productData?.final_product_id;
  }, [productData?.final_product_id]);

  //TODO in a hooks archive
  const relatedProducts = useMemo(() => {
    return productData?.cross_selling.map((product) => {
      return {
        slug: product?.slug,
        small_image: product?.small_image,
        small_image_hover: product?.small_image_hover,
        minimal_price: product?.minimal_price,
        name: product?.name,
        minimal_quantity: product?.minimal_quantity,
      };
    });
  }, [productData?.cross_selling]);

  const hasRelatedProducts = !!relatedProducts?.length;

  const configurationRef = useRef<HTMLDivElement>(null);
  const shippingRef = useRef<HTMLDivElement>(null);
  const specificationsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!productData) {
      return;
    }
    fetchStars({ setStarsCount });
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item',
      event_name: 'view_item',
      ecommerce: {
        currency: 'BRL',
        items: [
          {
            item_id: productData.final_product_id,
            item_name: productData.name,
            affiliation: 'Printi',
            discount: 0,
            index: 0,
            item_brand: 'Printi',
            item_category: cmsProductData?.main_category,
            price: productData.min_price?.value,
            quantity: productData.min_price?.quantity,
          },
        ],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productData]);

  return (
    <div className="fs-container">
      <S.Container>
        <Overview
          slug={slug}
          productData={productData}
          {...quantity}
          specificationsRef={specificationsRef}
        />
        <S.Content>
          <Configuration
            ref={configurationRef}
            shippingRef={shippingRef}
            productData={productData}
          />
          {!isMobile && (
            <S.RightContent>
              <Summary
                {...quantity}
                parts={parts}
                isLoading={isLoadingQuantities}
                economicShippingPrice={economicShipping?.price}
                shippingRef={shippingRef}
                product={productData}
                isLoadingShipping={isLoadingShipping}
              />
            </S.RightContent>
          )}
        </S.Content>
        <Suspense fallback={<p>Loading...</p>}>
          <Resources finalProductId={finalProductId} />
        </Suspense>
        {isMobile && (
          <FixedBar
            {...quantity}
            isLoading={isLoadingQuantities}
            economicShippingPrice={economicShipping?.price}
            shippingRef={shippingRef}
            configurationRef={configurationRef}
            product={productData}
            isLoadingShipping={isLoadingShipping}
          />
        )}
        {!!cmsProductData?.content && (
          <Specifications
            content={cmsProductData?.content}
            faq={productFaq}
            ref={specificationsRef}
          />
        )}
        <HistogramRate
          _productId={productData?.final_product_id}
          starsCount={starsCount}
        />
        {hasRelatedProducts && (
          <CarouselRelatedProducts
            heading="Você também pode gostar"
            cards={relatedProducts}
          />
        )}
      </S.Container>
    </div>
  );
}
