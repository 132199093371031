import { RefObject } from 'react';
import { FsButtonMini } from '@printi/ds-offset-react-core';
import * as S from './ShortDescription.styles';

interface ShortDescriptionProps {
  description?: string;
  hasContent: boolean;
  specificationsRef: RefObject<HTMLDivElement>;
}

export const ShortDescription = ({
  description,
  hasContent,
  specificationsRef,
}: ShortDescriptionProps) => {
  const onClick = () => {
    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'click_product_description',
    });
    if (specificationsRef?.current) {
      const elementPosition =
        specificationsRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: elementPosition - 120, behavior: 'smooth' });
    }
  };

  return (
    <>
      <S.Container onClick={onClick}>
        <S.Paragraph>{description}</S.Paragraph>
      </S.Container>
      {hasContent && <FsButtonMini onClick={onClick}>Exibir mais</FsButtonMini>}
    </>
  );
};
