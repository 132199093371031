import { useCallback } from 'react';
import { TParts } from '@/services/product/types';

interface GetOptionNameProps {
  parts?: TParts[];
  partId: string;
  attributeId: string;
  optionId: string;
}

interface GetPartNameProps {
  parts?: TParts[];
  partId: string;
}

export const useChosenConfigurationHooks = () => {
  const getOptionName = useCallback(
    ({ parts, partId, attributeId, optionId }: GetOptionNameProps) => {
      const part = parts?.find((part) => part.id === partId);

      if (!part) {
        return '';
      }

      const attribute = attributeId as keyof typeof part.attributes_options;
      const options = part.attributes_options[attribute];

      if (!options) {
        return '';
      }

      const option = options.find((option) => option.id === optionId);

      if (!option) {
        return '';
      }

      return option.name;
    },
    [],
  );

  const getPartName = useCallback(({ parts, partId }: GetPartNameProps) => {
    const part = parts?.find((part) => part.id === partId);

    if (!part) {
      return '';
    }

    return part.name;
  }, []);

  return {
    getPartName,
    getOptionName,
  };
};
