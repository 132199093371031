/* eslint-disable import/order */
import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import {
  ButtonSecondarySize,
  FsButtonMini,
  FsButtonSecondary,
  FsDescription,
  FsHeading,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import { useLayout } from '@mf/common/components/Media/Layout';
import { useEffect, useMemo, useState } from 'react';
import { QuantitiesList } from '../QuantitiesList';
import { ModalCustomQuantity } from '../ModalCustomQuantity';
import { LoadingQuantities } from '../LoadingQuantities';
import * as S from './Quantities.styles';
import { useQuantitiesHooks } from './Quantities.hooks';
import { TCustomQuantity } from '@/services/product/types';

interface QuantitiesProps {
  customQuantity?: TCustomQuantity;
  final_product_id?: string;
}

export const Quantities = ({
  customQuantity,
  final_product_id,
}: QuantitiesProps) => {
  const [viewMore, setViewMore] = useState<boolean>(false);
  const [openModalCustomQuantity, setOpenModalCustomQuantity] =
    useState<boolean>(false);

  const { isMobile } = useLayout();

  const {
    totalSteps,
    isAllConfigured,
    customCombination,
    customQuantityOption,
    setIsInvalidSummary,
    setIsLoadingQuantities,
  } = useProductConfigurationStore();

  const {
    quantities,
    isLoading,
    fetchQuantities,
    selectFirstQuantity,
    selectCustomQuantity,
  } = useQuantitiesHooks();

  useEffect(() => {
    fetchQuantities({
      isAllConfigured,
      final_product_id,
      customCombination,
      customQuantityOption,
    });
  }, [
    fetchQuantities,
    isAllConfigured,
    final_product_id,
    customCombination,
    customQuantityOption,
  ]);

  useEffect(() => {
    if (customQuantityOption) {
      return;
    }
    selectFirstQuantity({ quantities });
  }, [quantities, selectFirstQuantity, customQuantityOption]);

  useEffect(() => {
    if (!customQuantityOption) {
      return;
    }
    selectCustomQuantity({ quantities, customQuantityOption });
  }, [customQuantityOption, quantities, selectCustomQuantity]);

  useEffect(() => {
    setIsLoadingQuantities(isLoading);
  }, [isLoading, setIsLoadingQuantities]);

  const filteredQuantities = useMemo(() => {
    if (viewMore) {
      return quantities;
    }
    return quantities?.slice(0, 8);
  }, [quantities, viewMore]);

  useEffect(() => {
    if (!quantities?.length) {
      setIsInvalidSummary(true);
    } else {
      setIsInvalidSummary(false);
    }
  }, [setIsInvalidSummary, quantities]);

  return (
    <S.Container>
      <S.TitleWrapper>
        <S.TitleContent>
          <FsHeading
            size={isMobile ? HeadingSize.MD : HeadingSize.LG}
            tag={HeadingTag.H2}
          >
            Quantidade
          </FsHeading>
          {isMobile ? (
            <FsButtonMini
              onClick={() => {
                window.dataLayer.push({
                  event: 'genericEvent',
                  event_name: 'start_personalization_quantity',
                });
                setOpenModalCustomQuantity(true);
              }}
              disabled={!isAllConfigured}
            >
              Personalizar
            </FsButtonMini>
          ) : (
            <FsButtonSecondary
              size={ButtonSecondarySize.LG}
              icon={'EditLine'}
              onClick={() => {
                window.dataLayer.push({
                  event: 'genericEvent',
                  event_name: 'start_personalization_quantity',
                });
                setOpenModalCustomQuantity(true);
              }}
              disabled={!isAllConfigured}
            >
              Personalizar
            </FsButtonSecondary>
          )}
        </S.TitleContent>
        <FsDescription>
          Etapa {totalSteps - 1} de {totalSteps}
        </FsDescription>
      </S.TitleWrapper>
      <S.Content>
        {isLoading ? (
          <LoadingQuantities />
        ) : (
          <>
            <QuantitiesList quantities={filteredQuantities} />
            <FsDescription>
              A economia se baseia no valor por unidade da quantidade mínima
              padrão.
            </FsDescription>
            {quantities && quantities?.length > 8 && (
              <FsButtonMini onClick={() => setViewMore((state) => !state)}>
                {viewMore ? 'Ver menos' : 'Ver mais'}
              </FsButtonMini>
            )}
          </>
        )}
      </S.Content>
      <ModalCustomQuantity
        isOpen={openModalCustomQuantity}
        setOpen={setOpenModalCustomQuantity}
        customQuantity={customQuantity}
      />
    </S.Container>
  );
};
