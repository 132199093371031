/* eslint-disable @typescript-eslint/no-explicit-any */
import { FsGalleryProduct, FsPreviewVideo } from '@printi/ds-offset-react-core';
import Image from 'next/image';
import { useState } from 'react';
import { GalleryProps, ImagesType } from './Gallery.types';
import * as S from './Gallery.styles';

export const Gallery = ({ name, final_product_id, banner }: GalleryProps) => {
  const [videoVisible, setVideoVisible] = useState<boolean>(false);

  const galleryImages = () => {
    if (!banner?.images) {
      return [];
    }

    const galleryItems = banner?.images?.map(
      (image: ImagesType | undefined, index: number) => {
        return {
          index: index + 1,
          full: (
            <Image
              src={image?.src || ''}
              alt={image?.alt || ''}
              width={600}
              height={450}
              loading="eager"
              fetchPriority="high"
            />
          ),
          thumb: (
            <Image
              src={
                image?.src ||
                'https://d1br4h274rc9sc.cloudfront.net/content/paper_b2591f4c2b.png'
              }
              alt={image?.alt || ''}
              width={295}
              height={150}
              loading="eager"
              fetchPriority="high"
            />
          ),
        } as any;
      },
    );

    return galleryItems;
  };

  return (
    <>
      <FsPreviewVideo
        src={banner?.video?.url}
        avatarSrc="./assets/images/avatar.png"
        showButton={true}
        showTestimony={false}
        showOverlay={true}
        name={name}
        visible={videoVisible}
        onClose={() => setVideoVisible(false)}
      />
      <S.GalleryContainer>
        <FsGalleryProduct
          images={galleryImages() as unknown as any[]}
          fullImageHeight={450}
          videoThumbnail={
            banner?.video?.url ? (
              <Image
                src={banner?.video?.thumbnail || ''}
                width={200}
                height={115}
                alt="Video preview"
              />
            ) : undefined
          }
          onVideoThumbnailClick={() => {
            setVideoVisible(true);
            window.dataLayer.push({
              event: 'genericEvent',
              event_name: 'video_clicked',
              event_parameter_1: final_product_id,
              event_parameter_2: name,
            });
          }}
        ></FsGalleryProduct>
      </S.GalleryContainer>
    </>
  );
};
