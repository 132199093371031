import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: var(--spacing-2x) 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-none);
  align-self: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4x);
  align-self: stretch;
`;

export const ActiveView = styled.div<{ $active: boolean }>`
  ${(props) => (props.$active ? 'display: block' : 'display: none')}
`;
