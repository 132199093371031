import {
  FsHeading,
  FsParagraph,
  HeadingSize,
  HeadingTag,
} from '@printi/ds-offset-react-core';
import * as S from './ArtPreview.styles';

export const ArtPreview = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Frame>
          <FsHeading size={HeadingSize.SM} tag={HeadingTag.H3}>
            Pré visualize sua arte
          </FsHeading>
        </S.Frame>
        <S.TextWrapper>
          <FsParagraph>
            Adicione seu produto ao carrinho e veja uma prévia da arte antes de
            finalizar o pedido
          </FsParagraph>
          <div style={{ height: 'var(--spacing-6x)' }}></div>
        </S.TextWrapper>
      </S.Content>
    </S.Container>
  );
};
