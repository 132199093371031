import { useProductConfigurationStore } from '@mf/common/store/product-configuration';
import { TCombination } from '@mf/common/store/product-configuration';
import { useCallback } from 'react';
import productService from '@/services/product';
import { TDefaultCombinationsQuantities } from '@/services/product/types';

interface FetchQuantitiesProps {
  final_product_id?: string;
  customCombination: TCombination;
  isAllConfigured: boolean;
  customQuantityOption: number | null;
}

interface SelectFirstQuantityProps {
  quantities?: TDefaultCombinationsQuantities[];
}

interface SelectCustomQuantityProps {
  quantities?: TDefaultCombinationsQuantities[];
  customQuantityOption: number | null;
}

export const useQuantitiesHooks = () => {
  const { selectQuantity } = useProductConfigurationStore();

  const { useProductQuantity } = productService({
    baseUrl: process.env.BFF_API_BASE_URL,
  });

  const {
    mutate: mutateQuantity,
    isLoading,
    isSuccess,
    data: quantities,
  } = useProductQuantity();

  const fetchQuantities = useCallback(
    ({
      final_product_id,
      customCombination,
      isAllConfigured,
      customQuantityOption,
    }: FetchQuantitiesProps) => {
      if (!isAllConfigured || !final_product_id) {
        return;
      }

      const product_parts = customCombination.parts;

      const quantities = customQuantityOption
        ? ['default', customQuantityOption]
        : undefined;

      const payload = {
        product_parts,
        final_product_id,
        ...(quantities && { quantities }),
      };

      mutateQuantity(payload);
    },
    [mutateQuantity],
  );

  const selectFirstQuantity = useCallback(
    ({ quantities }: SelectFirstQuantityProps) => {
      if (!quantities?.length) {
        return;
      }
      selectQuantity(quantities[0]);
    },
    [selectQuantity],
  );

  const selectCustomQuantity = useCallback(
    ({ quantities, customQuantityOption }: SelectCustomQuantityProps) => {
      if (!quantities?.length || !customQuantityOption) {
        return;
      }

      const customQuantity = quantities.find(
        (quantity) => quantity.quantity === customQuantityOption,
      );

      if (customQuantity) {
        selectQuantity(customQuantity);
      }
    },
    [selectQuantity],
  );

  return {
    isLoading,
    isSuccess,
    quantities,
    fetchQuantities,
    selectFirstQuantity,
    selectCustomQuantity,
  };
};
