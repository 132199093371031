import styled from 'styled-components';

export const Container = styled.div`
  width: -webkit-fill-available;
  display: flex;
  padding: var(--shape-spacing-4x, 32px) var(--shape-spacing-4x, 32px)
    var(--spacing-1_5x) var(--shape-spacing-4x, 32px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--shape-spacing-4x, 32px);
  align-self: stretch;
  height: fit-content;

  border-radius: 16px;
  border: 1.6px solid var(--elements-border-color);
`;

export const ListGroup = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2x);
  align-self: stretch;
`;

export const ListContent = styled.div`
  display: flex;
  padding: var(--spacing-none);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-1x);
  align-self: stretch;

  > div {
    width: 100%;
  }

  > div {
    &[type='square'] {
      margin-bottom: 10px;
      height: 14px !important;
      width: 100% !important;
    }
  }
`;

export const BuyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`;

export const ShippingWrapper = styled.div`
  display: flex;
  height: var(--size-3x);
  padding: var(--spacing-none);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  > div {
    &[type='square'] {
      height: 38px !important;
      width: 140px;
    }
  }
`;
