import { FsButtonMini, FsParagraph } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Paragraph = styled(FsParagraph)<{ showMore?: boolean }>(
  ({ showMore }) => `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${showMore ? 'unset' : '5'};
    -webkit-box-orient: vertical;
    cursor: pointer;
    transition: -webkit-line-clamp 0.5s;
`,
);

export const TextOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
`;

export const ButtonMini = styled(FsButtonMini)``;
