'use client';

/* eslint-disable import/order */
import { useEffect } from 'react';
import { Attribute } from '../Attribute';
import { useAttributesHooks } from './Attributes.hooks';
import {
  TAttributeOptions,
  TCustomFormat,
  TCustomPage,
  TPartsAttributes,
} from '@/services/product/types';

interface AttributeProps {
  attributes: TPartsAttributes[];
  attributesOptions: TAttributeOptions;
  partId: string;
  customFormat?: TCustomFormat;
  customPage?: TCustomPage;
}

export const Attributes = ({
  partId,
  customPage,
  attributes,
  customFormat,
  attributesOptions,
}: AttributeProps) => {
  const {
    getOptions,
    verifyIsCustomPage,
    verifyIsCustomFormat,
    verifyInvisibleOptions,
  } = useAttributesHooks();

  useEffect(() => {
    verifyInvisibleOptions({
      partId,
      attributes,
      attributesOptions,
    });
    // eslint-disable-next-line
  }, [partId, attributes, attributesOptions]);

  return (
    <>
      {attributes.map((attribute) => {
        const attributeId = attribute.id;
        const isVisible = attribute.visible;
        const options = getOptions({ attributeId, attributesOptions });
        const isCustomPage = verifyIsCustomPage({ attributeId, customPage });
        const isCustomFormat = verifyIsCustomFormat({
          attributeId,
          customFormat,
        });

        if (!isVisible || !options.length) {
          return <></>;
        }

        return (
          <Attribute
            key={attributeId}
            options={options}
            partId={partId}
            isCustomFormat={isCustomFormat}
            isCustomPage={isCustomPage}
            customFormat={customFormat}
            customPage={customPage}
            {...attribute}
          />
        );
      })}
    </>
  );
};
